import { Button, Card, Modal } from 'react-bootstrap';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { List, Progress } from 'antd';
import { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { asyncWrap } from '../../utils/utils';
import axios from 'axios';
import CloseModalTab from '../../component/Nav/CloseModalTab';
import GiftDetailModal from './GiftDetailModal';
import Swal from 'sweetalert2';
import { useAuth } from '../../context/auth-context';

const CampaignRedeem = ({ show, setShow }) => {
  const [campaignData, setCampaignData] = useState();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const getCampaignData = async () => {
    const [error, result] = await asyncWrap(axios.get('/campaign'));
    if (error?.response?.status === 401) {
      Swal.fire('Please Logout and login again');
      return;
    }
    if (!result) {
      console.log(error.response);
      return;
    } else {
      const CampaignData = result.data?.data.filter(
        item =>
          item.BalancePoints !== "0" &&
          item.CampaignName !== 'Diwali Scheme Points Earned' &&
          item.CampaignName !== 'Year End 23-24 Scheme Points Earned'
      );

      const campaign_data = CampaignData.filter(
        item => !item.CampaignName.toLowerCase().includes('other')
      );

      setCampaignData(campaign_data);
    }
  };

  const redeemCampaign = async (point, campaignId, campaignName, commit) => {
    const [error, result] = await asyncWrap(
      axios.post('/addRedeem', {
        points: point,
        type: 'Bank',
        campaignId: campaignId,
        campaignName: campaignName,
        commit: commit,
      })
    );
    if (!error) {
      setLoading(false);
      Swal.fire(result.data.message).then(() => {
        console.log('Alert closed');
        getCampaignData();
      });
      return;
    }
    Swal.fire(error.data.message).then(() => {
      setLoading(false);
      window.location.reload();
    });
  };

  useEffect(() => {
    getCampaignData();
  }, []);

  return (
    <>
      <Modal fullscreen={true} show={show}>
        {loading ? (
          <div className='quiz-loader-main'>
            <div id='quiz-loader'></div>
          </div>
        ) : (
          <Modal.Body className='w-100 p-2 mt-3'>
            <CloseModalTab onclick={() => setShow(false)} />

            <div>
              <List
                style={{ marginTop: '10px' }}
                dataSource={campaignData}
                renderItem={(item, key) => (
                  <List.Item className='my-3 shadow rounded-3 p-3' key={key}>
                    <div className='w-75'>
                      <div
                        style={{ color: '#434343', fontSize: '16px' }}
                        className='fw-bold'
                      >
                        {item.CampaignName}
                      </div>
                    </div>
                    <div
                      style={{
                        color: '#3A69DD',
                        fontSize: '20px',
                        width: '100%',
                      }}
                      className='fw-bold d-flex flex-row justify-content-between align-items-end'
                    >
                      <div>🏆 {item?.BalancePoints}</div>
                      {[
                        'Udaan 5.0',
                        'Festival Bonanza 2024',
                        'Monsoon Bonanza 2024',
                      ].includes((item?.CampaignName).trim()) &&
                        (((user?.profile?.StateName || user?.StateName) ===
                          'WEST BENGAL') ||
                          ((user?.profile?.StateName || user?.StateName) ===
                            'West Bengal')) && (
                          <Button
                            onClick={() => {
                              setLoading(true);
                              redeemCampaign(
                                item.BalancePoints,
                                item.CampaignId,
                                item.CampaignName,
                                item.CampaignName + item.BalancePoints
                              );
                            }}
                            style={{ width: 'auto' }}
                          >
                            Redeem
                          </Button>
                        )}
                      {item?.CampaignName === 'Summer Bonanza 2024' && (
                        <Button
                          onClick={() => {
                            setLoading(true);
                            redeemCampaign(
                              item.BalancePoints,
                              item.CampaignId,
                              item.CampaignName,
                              item.CampaignName + item.BalancePoints
                            );
                          }}
                          style={{ width: 'auto' }}
                        >
                          Redeem
                        </Button>
                      )}
                    </div>
                    <div></div>
                  </List.Item>
                )}
              />
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default CampaignRedeem;
