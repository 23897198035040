import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Card, Typography } from 'antd';
import { useAuth } from '../../context/auth-context';
import moment from 'moment';
import { asyncWrap } from '../../utils/utils';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';

const { Title, Text } = Typography;
const { TextArea } = Input;

const levels = [
  { level: 'L1', points: 8000 },
  { level: 'L2', points: 13700 },
  { level: 'L3', points: 20000 },
  { level: 'L4', points: 27500 },
  { level: 'L5', points: 40000 },
  { level: 'L6', points: 60000 },
  { level: 'L7', points: 85000 },
  { level: 'L8', points: 150000 },
  { level: 'L9', points: 215000 },
  { level: 'L10', points: 312500 },
  { level: 'L11', points: 414500 },
  { level: 'L12', points: 552500 },
  { level: 'L13', points: 730000 },
  { level: 'L14', points: 1000000 },
  { level: 'L15', points: 1400000 },
];

const UdaanStatus = () => {
  const [query, setQuery] = useState('');
  const [totalUdaanPoints, setTotalUdaanPoints] = useState(0);
  const [userData, setUserData] = useState({});
  const [userDetails, setUserDetails] = useState();

  const { user } = useAuth();
  const history = useHistory();

  const rows = [
    {
      title: 'Udaan 5.0 Wires (All Redemptions)',
      dataIndex: 'wires',
      key: 'wires',
    },
    { title: 'Udaan 5.0 Switches', dataIndex: 'switches', key: 'switches' },
    { title: 'Udaan 5.0 MCB', dataIndex: 'mcb', key: 'mcb' },
    { title: 'Festival Udaan', dataIndex: 'festival', key: 'festival' },
    { title: 'Monsoon Bonanza Udaan', dataIndex: 'monsoon', key: 'monsoon' },
  ];

  const data = [
    {
      key: '1',
      wires: 100,
      switches: 50,
      mcb: 30,
      festival: 40,
      monsoon: 20,
    },
  ];

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    const [error, result] = await asyncWrap(axios.get('/userDetails'));
    if (error?.response?.status === 401) {
      Swal.fire('Please Logout and login again');
      return;
    }
    if (!result) {
      console.log(error);
      return;
    } else {
      const userDetails = result?.data?.data || {};
      setTotalUdaanPoints(
        parseInt(result?.data?.data?.TotalUdaanEligiblity || 0)
      );
      setQuery(result?.data?.data?.LinkedInProfile || '');
      setUserData({
        wires: userDetails?.UdaanFY2425,
        switches: userDetails?.SwitchesScanningFY2425,
        mcb: userDetails?.MCBScanningFY2425,
        festival: userDetails?.FestivalUdaanFY2425,
        monsoon: userDetails?.MonsoonBonanzaFY2425,
      });
      setUserDetails(userDetails);
    }
  };

  const submitQuery = async () => {
    if (query === '') {
      Swal.fire('Please write your query');
      return;
    }
    const [error, result] = await asyncWrap(
      axios.post('/editProfile', {
        userType: user?.profile?.roleName || user?.roleName,
        userId: user?.profile?.userId || user.userId,
        linkedInProfile: query,
      })
    );
    if (!result) {
      Swal.fire(error.response.data.data.message);
      history.replace('/home');
    } else if (result.data?.success) {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire(result?.data?.message).then(() => {
        window.location.reload();
      });
    }
  };

  const getCurrentLevel = () => {
    if (totalUdaanPoints === 0) {
      return { currentLevel: 'N/A', nextLevelPoints: levels[0].points };
    }

    let currentLevel = 'N/A';
    let nextLevelPoints = levels[0].points;

    for (let i = levels.length - 1; i >= 0; i--) {
      if (totalUdaanPoints >= levels[i].points) {
        currentLevel = levels[i].level;
        nextLevelPoints = levels[i + 1]
          ? levels[i + 1].points
          : levels[i].points;
        break;
      }
    }
    return { currentLevel, nextLevelPoints };
  };

  const { currentLevel, nextLevelPoints } = getCurrentLevel();
  const pointsNeeded = Math.max(nextLevelPoints - totalUdaanPoints, 0);
  const daysLeft = moment('2025-03-31').diff(moment(), 'days');

  return (
    <>
      {userDetails && (
        <div style={{ padding: 20, maxWidth: 800, margin: 'auto' }}>
          <Title level={2} style={{ textAlign: 'center' }}>
            Udaan 5.0 Status
          </Title>
          <div style={{ marginTop: '5px', fontSize: '16px' }}>
            Name : {userDetails?.fullName}
          </div>
          <div style={{ marginTop: '5px', marginBottom:"10px", fontSize: '16px' }}>
            Mobile Number : {userDetails?.mobileNumber}
          </div>

          <Table
            columns={[
              { title: 'Category', dataIndex: 'title', key: 'title' },
              { title: 'Points', dataIndex: 'value', key: 'value' },
            ]}
            dataSource={rows.map(row => ({
              key: row.key,
              title: row.title,
              value: userData[row.dataIndex] || 0,
            }))}
            pagination={false}
            showHeader={false}
            className='custom-table-udaan-status'
            bordered
          />

          <div style={{ marginTop: '20px', fontSize: '16px' }}>
            Total Udaan (Sum of all the above) : {totalUdaanPoints} points
          </div>
          

          <Card style={{ marginTop: 20, textAlign: 'center' }}>
            <Text strong>Current Level as per Udaan:</Text>{' '}
            <Text>{currentLevel}</Text>
            <br />
            <Text strong>Points Required to Reach Next Level:</Text>{' '}
            <Text>{pointsNeeded}</Text>
            <br />
            <Text strong>Days Left Before Scheme Expiry:</Text>{' '}
            <Text>{daysLeft}</Text>
          </Card>

          <div style={{ marginTop: 20 }}>
            <p>
              If you have any query related to Udaan 5.0 Calculation - Please
              fill this text box with your query and our team will call you back
              and explain.
            </p>
            <p>
              यदि आपके पास उड़ान 5.0 गणना से संबंधित कोई प्रश्न है - तो कृपया
              अपने प्रश्न के साथ इस टेक्स्ट बॉक्स को भरें और हमारी टीम आपको वापस
              कॉल करेगी और समझाएगी।
            </p>
            <br />
            <TextArea
              rows={4}
              value={query}
              onChange={e => setQuery(e.target.value)}
              placeholder='Please enter your query here'
            />
            <button
              onClick={e => {
                e.preventDefault();
                submitQuery();
              }}
              style={{ background: '#3A69DD', color: 'white' }}
              className='fw-bold fs-5 shadow align-self-center rounded-5 px-4 py-2 border-0 mt-2'
            >
              Submit
            </button>
            {/* <Button type='primary' style={{ marginTop: 10 }}>
          Submit
        </Button> */}
          </div>
        </div>
      )}
    </>
  );
};

export default UdaanStatus;
